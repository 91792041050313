import request from "@http";

// 2023/04/19 王江毅 查询所有房间
export function getApartmentPageApi(params) {
    return request({
        method: "get",
        url: `/api/bzf/apartment/page`,
        params,
    });
}

// 2023/04/19 王江毅 添加房间
export function apartmentSave(data) {
    return request({
        method: "post",
        url: `/api/bzf/apartment/save`,
        data,
    });
}
// 2023/04/19 王江毅 修改房间
export function apartmentUpdate(data) {
    return request({
        method: "post",
        url: `/api/bzf/apartment/update`,
        data,
    });
}

// 2023/04/19 王江毅 查询房间详情
export function getApartmentInfo(params) {
    return request({
        method: "get",
        url: `api/bzf/apartment/info`,
        params,
    });
}


// 2023/04/19 王江毅 修改子房间
export function apartmentSonUpdateApi(data) {
    return request({
        method: "post",
        url: `/api/bzf/apartment/son/update`,
        data,
    });
}