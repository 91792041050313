<template>
    <section class="guaranteed-rental-housing-resources">
        <div class="top-bar bg-white">
            <el-cascader v-model="value" :options="options" size="small" :props="props"
                         style="margin-right: 10px;"/>
            <!--    搜索框-->
            <el-input v-model="formSearch.keyword" placeholder="请输入" style="margin-right: 10px; width: 250px" size="small"/>
            <!--    搜索框-->
            <el-input v-model="formSearch.floorNum" placeholder="请输入楼层" style="margin-right: 10px; width: 250px" size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button size="small" @click="handleResetting">重置</el-button>

            <el-button type="primary" icon="el-icon-upload" @click="openImport" size="small">导入</el-button>
            <el-button type="primary" icon="el-icon-download" size="small">导出</el-button>
            <el-button type="primary" icon="el-icon-refresh" size="small" @click="openConvert">转换</el-button>
            <el-button type="primary" icon="el-icon-plus" size="small" @click="addHousing('add', {})">新增</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getReadyPageList" :height="820" :columns="tableColumn"
                   :query="formSearch">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="!row.children" @click="saveInner('edit', row)">修改</span>
                        <span class="table-btn" v-if="row.children" @click="addHousing('edit', row)">修改</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <layer-add-housing ref="layerAddHousing" @handleChange="handleChange"/>
        <layer-save-inner ref="layerSaveInner" @handleChange="handleChange"/>
        <layer-housing-convert ref="layerHousingConvert" />
        <layer-housing-import ref="layerHousingImport" />
    </section>
</template>

<script>
import {tableColumn} from "@/views/rental-housing-management/guaranteed-rental-housing-resources/data";
import {getApartmentPageApi} from '@/api/guaranteed-rental-housing-resources';
import {getCommonityListApi, getDuildUnitListApi} from '@/api/community-management';

export default {
    name: "guaranteed-rental-housing-resources",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                floorNum: null,
                uuid: null,
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            districtList: [],
            addressDistrictList: [],
            value: [],
            options: [],
            props: this.propsP(),
        };
    },
    components: {
        layerAddHousing: () => import("@/views/rental-housing-management/guaranteed-rental-housing-resources/components/layer-add-housing.vue"),
        layerSaveInner: () => import('./components/layer-save-inner.vue'),
        layerHousingConvert: () => import('./components/layer-housing-convert.vue'),
        layerHousingImport: () => import('./components/layer-housing-import.vue'),
    },
    created() {
    },
    mounted() {
        this.getCommonityList();
    },
    methods: {
        getReadyPageList(params) {
            return getApartmentPageApi(params);
        },

        handleChange() {
            this.formSearch.uuid = this.value[this.value.length-1];
            this.$refs["tableRef"].getTableData();
        },

        handleResetting() {
            // this.formSearch = { };
            this.formSearch.keyword = null;
            this.formSearch.value = null;
            this.value = [];
            this.handleChange();
        },

        getCommonityList() {
            const that = this;
            getCommonityListApi().then(({list}) => {
                that.options = list;
            });
        },

        addHousing(addEdit, data) {
            this.$refs['layerAddHousing'].openDialog(addEdit, data);
        },

        saveInner(addEdit, data) {
            this.$refs['layerSaveInner'].openDialog(addEdit, data);
        },

        openConvert() {
            this.$refs['layerHousingConvert'].openDialog();
        },

        propsP() {
            const that = this;
            return {
                value: 'uuid',
                label: 'name',
                lazy: true,
                async lazyLoad(node, resolve) {
                    const {level, value} = node;
                    if (level === 3) resolve([]);

                    const nodes = await that.getDuildUnitList(value, level === 1 ? 3 : level);
                    resolve(nodes);
                }
            }
        },

        async getDuildUnitList(uuid,type) {
            const {list} = await getDuildUnitListApi({uuid, type});
            return list;
        },

        openImport(){
            this.$refs["layerHousingImport"].openDialog();
        }
    },
}
</script>


<style lang="scss" scoped>
.guaranteed-rental-housing-resources {
    padding: 0 VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(15px);
        padding: VH(15px) VW(15px);
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            //text-align: center;
        }
    }
}
</style>