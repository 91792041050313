import request from "@http";

// 2023/04/19 王江毅 查询所有小区
export function getCommonityPageApi(params) {
    return request({
        method: "get",
        url: `/api/bzf/commonity/page`,
        params,
    });
}

// 2023/04/19 王江毅 添加小区
export function commonitySave(data) {
    return request({
        method: "post",
        url: `/api/bzf/commonity/save`,
        data,
    });
}

// 2023/04/19 王江毅 修改小区
export function commonityUpdate(data) {
    return request({
        method: "post",
        url: `/api/bzf/commonity/update`,
        data,
    });
}

// 2023/04/19 王江毅 添加楼栋/单元
export function buildUnitSave(data) {
    return request({
        method: "post",
        url: `/api/bzf/buildUnit/save`,
        data,
    });
}

// 2023/04/19 王江毅 修改单元/楼栋
export function buildUnitUpdate(data) {
    return request({
        method: "post",
        url: `/api/bzf/buildUnit/update`,
        data,
    });
}

// 2023/04/19 王江毅 查询楼栋、单元详情
export function buildUnitInfo(params) {
    return request({
        method: "get",
        url: `/api/bzf/buildUnit/info`,
        params,
    });
}

// 2023/04/20 王江毅 下拉框查询所有小区
export function getCommonityListApi() {
    return request({
        method: "get",
        url: `/api/bzf/commonity/list`,
    });
}

// 2023/04/20 王江毅 下拉框查询楼栋、单元
export function getDuildUnitListApi(params) {
    return request({
        method: "get",
        url: `/api/bzf/build/unit/list`,
        params,
    });
}
