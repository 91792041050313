export const tableColumn = [
    {
        prop: "community.name",
        label: "小区",
        showOverflowTooltip: true
    },
    {
        prop: "build.buildUnitNum",
        label: "楼幢",
    },
    {
        prop: "unit.buildUnitNum",
        label: "单元",
    },
    {
        prop: "floorNum",
        label: "楼层",
    },
    {
        prop: "roomNum",
        label: "房间"
    },
    {
        prop: "children.length",
        label: "房间数量(间)"
    },
    {
        prop: "area",
        label: "房屋面积(㎡)"
    },
    {
        prop: "areaIn",
        label: "套内面积(㎡)"
    },
    {
        prop: "sharedArea",
        label: "公摊面积(㎡)"
    },
    {
        prop: "common",
        label: "备注"
    },
];

